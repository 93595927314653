import React, { useEffect, useState } from 'react';

export const ModernTabs = ({ children, updateTabIndex, hideMainTabs, inverse, onTabClick, overrideTabs=false, activeTab, setActiveTab }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleTabClick = (tabIndex) => {
    setActiveTabIndex(tabIndex === activeTabIndex ? activeTabIndex : tabIndex)

    if (onTabClick) {
      onTabClick(tabIndex);
    }
  }

  const renderChildrenWithTabProps = () => {
    return React.Children.map(children, (child, index) => {
      return React.cloneElement(child, {
        onClick : handleTabClick,
        tabIndex: index,
        isActive: index === activeTabIndex,
        inverseColor: inverse ? true : false,
      });
    });
  }

  const renderActiveTabContent = () => {
    if(children[activeTabIndex]) {
      return children[activeTabIndex].props.children;
    }
  }

  const handleTabClickAlt = (tabIndex) => {
    setActiveTab(tabIndex === activeTab ? activeTab : tabIndex)

    if (onTabClick) {
      onTabClick(tabIndex);
    }
  }

  const renderChildrenWithTabPropsAlt = () => {
    return React.Children.map(children, (child, index) => {
      return React.cloneElement(child, {
        onClick : handleTabClickAlt,
        tabIndex: index,
        isActive: index === activeTab,
        inverseColor: inverse ? true : false,
      });
    });
  }

  const renderActiveTabContentAlt = () => {
    if(children[activeTab]) {
      return children[activeTab].props.children;
    }
  }

  useEffect(() => {
    if(updateTabIndex) {
      updateTabIndex(renderChildrenWithTabProps());
    }
  }, [activeTabIndex]);

  return (
    <div className="just-tabs">
      {!hideMainTabs && !overrideTabs && (
        <div className="tabs__navigation">
          {renderChildrenWithTabProps()}
        </div>
      )}
      {!overrideTabs && <div className="tabs__activeContent">
        {renderActiveTabContent()}
      </div>}
      {!hideMainTabs && overrideTabs && (
        <div className="tabs__navigation">
          {renderChildrenWithTabPropsAlt()}
        </div>
      )}
      {overrideTabs && 
      <div className="tabs__activeContent">
        {renderActiveTabContentAlt()}
      </div>}
    </div>
  );
};
