import React from 'react';
import PropTypes from 'prop-types';

export const Text = ({
  children,
  color,
  as,
  height,
  centered,
  absolute,
  fontSize,
  fontFamily,
  lineHeight,
  fontWeight,
  fontStyle,
  letterSpacing
}) => {
  const CustomTag = as || 'p';

  return (
    <CustomTag
      className={
        `text-container
          text-container--${centered ? 'centered' : 'left'}
          ${absolute ? 'text-container--absolute' : ''}
          ${CustomTag}`
      }
      style={{
        color: color || null,
        fontSize: fontSize || null,
        fontFamily: fontFamily || null,
        lineHeight: lineHeight || null,
        fontWeight: fontWeight || null,
        letterSpacing: letterSpacing || null,
        fontStyle: fontStyle || null,
        height: height ? `${height}px` : undefined,
      }}
    >
      {children}
    </CustomTag>
  );
};

Text.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  fontFamily: PropTypes.string,
  fontSize: PropTypes.string,
  lineHeight: PropTypes.string,
  centered: PropTypes.bool,
  absolute: PropTypes.bool,
  children: PropTypes.node.isRequired,
  as: PropTypes.string,
  fontWeight: PropTypes.string,
  fontStyle: PropTypes.string,
  letterSpacing: PropTypes.string
};

Text.defaultProps = {
  color: '#000000',
  as: undefined,
};
