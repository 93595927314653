
import React from 'react';

export const ModernTab = ({ onClick, tabIndex, isActive, label, className, inverseColor }) => {
  const handleTabClick = (e) => {
    e.preventDefault();
    onClick(tabIndex);
  }

  return (
    <div className="tab__container">
      <div
        className={`tab__item tab__item${isActive ? '--active' : '--standard'} ${className || ''} ${isActive ? 'active' : ''} ${inverseColor ? 'inverse' : ''}`}
        onClick={handleTabClick}
      >
        <div>{label}</div>
      </div>
    </div>
  );
}
