import React, { Fragment, useEffect, useState } from 'react';
import { get } from 'lodash';
import {
  ListItem,
  Section
} from '..';
import { slugify } from '../../../../../utils/string_util';

export const FranchiseList = ({ items, interstitialConfig, sortable = true, initialSort, bgColor, params={}, preventEvent=false }) => {
  const [data, setData] = useState(items || []);
  const [activeListItem, setActiveListItem] = useState(undefined)
  const [sortType, setSortType] = useState(initialSort || "rank")

  const sortArray = type => {
    const types = {
      rank: 'rank',
      title: 'title',
    };
    const sortProperty = types[type];
    const sorted = [...items].sort((a, b) => a[sortProperty] < b[sortProperty] ? -1 : 1);
    setData(sorted);
  };

  useEffect(() => {
    sortArray(sortType);
  }, [items, sortType]);

  const toggleListItem = (index) => {
    if(activeListItem === index) {
      // Current item, collapse it
      setActiveListItem(undefined);
    }else{
      // New item, expand it
      setActiveListItem(index);
    }
  }

  const handleAnchors = () => {
    const anchorHash = get(window, 'location.hash');

    let currentIndex;
    if (anchorHash) {
      items.forEach((item, i) => {
        if (slugify(item.title) === anchorHash.replace('#', '')) {
          currentIndex = i;
        }
      });
    }

    if (currentIndex){
      setActiveListItem(currentIndex);
      const initialElement = document.getElementById(`list-item-index-${currentIndex}`);
      initialElement.scrollIntoView();
    }
  }

  const handleParams = () => {
    const rank = get(params, 'rank', undefined);
    if(rank && params) {
      const distance = window.pageYOffset + document.querySelector(`#list-item-index-${parseInt(rank, 10) - 1}`).getBoundingClientRect().top;
      window.scrollTo({ top: distance - 90, left: 0, behavior: 'smooth' });
    }
  }

  useEffect(() => {
    handleAnchors();
    handleParams();
  }, []);

  return (
    <div className="franchiseList__container">
      {sortable && (
        <div className="franchiseList__sorting">
          <span>SORT BY</span>&nbsp;
          <select onChange={(e) => setSortType(e.target.value)}>
            <option value="rank">Rank</option>
            <option value="title">Name</option>
          </select>
        </div>
      )}
      {data.map((item, i) => {
        const { ListElement } = item;
        const isActive = activeListItem === i;
        const isInterstitial = interstitialConfig && i !== 0 && Number.isInteger(i / interstitialConfig.every);
        const ListItemComponent = ListElement || ListItem;

        return (
          <Fragment>
            {isInterstitial && (
              <div
                key={`franchiseList_item_interstitial_${i}`}
                className={`franchiseList__item__container franchiseList__item__container--interstitial`}
              >
                <Section name={`franchiseList__interstitial pos-${i}`} bgColor={get(interstitialConfig, 'bgColor', '#f7f7f7')}>
                  {interstitialConfig.renderComponent((i / interstitialConfig.every) - 1)}
                </Section>
              </div>
            )}

            <div
              key={`franchiseList_item_${i}`}
              id={`list-item-index-${i}`}
              className={`franchiseList__item__container`}
              onClick={(e) => {
                if(e.target.closest('.listItem__actions')) return false;
                if(preventEvent) return false;
                toggleListItem(i);
              }}
            >
              <ListItemComponent
                active={isActive}
                activeBgColor="#ffffff"
                bgColor={bgColor}
                {...item}
              />
            </div>
          </Fragment>
        );
      })}
    </div>
  )
};
