import React from 'react';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';
import ConditionalWrapper from '../../utils/ConditionalWrapper';
import { slugify } from '../../../../../utils/string_util';
import clsx from 'clsx';

export const Section = ({
  children,
  bgColor,
  maxWidth,
  maxWidthContainer,
  setCurrColor,
  noPadding,
  centered,
  zIndex,
  cover,
  borderBottom,
  bgVideo,
  margin,
  marginLeft,
  width,
  position,
  borderTop,
  name,
  addClass,
  onWayPointEnter,
  slug
}) => (
  <section
    className={clsx('section__container', noPadding && 'noPadding', name && `section__${name}`, addClass && addClass)}
    id={slug}
    style={{
      maxWidth: maxWidthContainer ? `${maxWidthContainer}px` : 'initial',
      margin: margin,
      borderTop: borderTop,
      position,
      width,
      backgroundColor: bgColor || '#ffffff',
      zIndex: zIndex || 'initial',
      marginLeft: marginLeft || 'initial',
      height: cover ? '100vh' : 'auto',
      borderBottom: borderBottom ? borderBottom : 'none',
      clear: 'both'
    }}
  >
    <Waypoint
      onEnter={() => {
        if (setCurrColor) setCurrColor(bgColor);
        if (onWayPointEnter) onWayPointEnter(position);
      }}
      topOffset="-50%"
    />

    {bgVideo && (
      <div style={{width: '100%', margin: '0 auto'}}>
        <video className="section__video" autoPlay muted loop>
          <source src={bgVideo} />
          Sorry, this browser does not support the `video` element.
        </video>
      </div>
    )}

    <ConditionalWrapper
      condition={bgVideo}
      wrapper={children => (<div className="section__videoWrapper">{children}</div>)}
    >
      <div
        className='section__inner'
        style={{
          maxWidth: maxWidth ? `${maxWidth}px` : 'initial',
          margin: centered ? '0 auto' : 'initial'
        }}
      >
        {children}
      </div>
    </ConditionalWrapper>
  </section>
);

Section.propTypes = {
  bgColor: PropTypes.string,
  children: PropTypes.any,
  maxWidth: PropTypes.number,
  margin: PropTypes.string,
  setCurrColor: PropTypes.func,
  noPadding: PropTypes.bool,
  centered: PropTypes.bool,
  maxWidthContainer: PropTypes.string,
  borderTop: PropTypes.string,
  position: PropTypes.string,
  width: PropTypes.string,
  cover: PropTypes.bool,
  zIndex: PropTypes.number,
  borderBottom: PropTypes.string,
  marginLeft: PropTypes.string,
  bgVideo: PropTypes.string,
  name: PropTypes.string,
  addClass: PropTypes.string
};

Section.defaultProps = {
  bgColor: undefined,
  maxWidth: null,
  maxWidthContainer: null,
  margin: null,
  noPadding: false,
  centered: true
};
